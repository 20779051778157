export function easyEntoryForm() {
  const form = document.querySelector('#js-form');
  if (!form) return;

  const checkBoxes = form.querySelectorAll('input[type="checkbox"]');
  const submitLink = form.querySelector('a');
  const href = submitLink.getAttribute('href');

  checkBoxes.forEach((checkBox) => {
    checkBox.addEventListener('click', () =>
      updateLinkState(checkBoxes, submitLink, href)
    );
  });
}

function updateLinkState(checkBoxes, submitLink, href) {
  // 選択されたチェックボックスのvalueを取得
  const selectedValues = Array.from(checkBoxes)
    .filter((cb) => cb.checked)
    .map((cb) => encodeURIComponent(cb.value));

  // refとexperiencesパラメータを更新
  const newHref = addQueryParams(href, {
    ref: 'experience_top',
    experiences: selectedValues.join(','),
  });
  submitLink.setAttribute('href', newHref);

  // リンクのクラスを更新
  if (selectedValues.length > 0) {
    submitLink.classList.remove('-disabled');
  } else {
    submitLink.classList.add('-disabled');
  }
}

// クエリパラメータを追加する
function addQueryParams(path, params) {
  const queryParams = [];
  Object.keys(params).forEach((key) => {
    queryParams.push(`${key}=${params[key]}`);
  });
  return `${path}?${queryParams.join('&')}`;
}
